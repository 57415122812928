import React from 'react'
import { IoMailOpenOutline } from "react-icons/io5";
import Header from '../../../component/Header';

export default function Sent() {
    return (
        <>
            <Header />
            <div className="h-[100dvh] w-full flex justify-center items-center bg-black font-body p-4">
                <div className="w-full max-w-sm md:max-w-md h-[450px] md:h-[550px] bg-white flex flex-col justify-center items-center p-6 md:p-10 space-y-6 font-body rounded shadow-lg">


                    <div className="rounded-full border-2 border-logo h-16 w-16 md:h-20 md:w-20 flex justify-center items-center">
                        <IoMailOpenOutline className="text-4xl md:text-5xl text-logo" />
                    </div>
                    <h1 className="text-xl md:text-3xl tracking-wider font-semibold">Check your E-mail</h1>
                    <p className="text-sm md:text-base text-gray-700 w-full text-center">
                        If the e-mail address admin@admin.com matches with a Saturday account, you will receive an e-mail to reset your password.
                    </p>
                    <button
                        type="button"
                        className="w-full h-12 border border-gray-300 hover:bg-logo hover:text-white rounded transition"
                    >
                        Resend e-mail
                    </button>
                </div>
            </div>
        </>
    )
}
