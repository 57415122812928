import React from 'react'
import Home from './pages/home/Home'
import { Routes, Route } from 'react-router-dom'
import Login from './pages/merchant/auth/Login'
import Forgot from './pages/merchant/auth/Forgot'
import Sent from './pages/merchant/auth/Sent'
import Notfound from './pages/constant/Notfound'

export default function App() {
  return (
    <Routes>
      {/* Normal Routes */}
      <Route path='/' element={<Home />} />

      {/* Merchant Routes */}
      <Route path='/merchant/login' element={<Login />} />
      <Route path='/merchant/reset-password' element={<Forgot />} />
      <Route path='/merchant/reset-link' element={<Sent />} />

      {/* 404 */}
      <Route path='/*' element={<Notfound />} />

    </Routes>
  )
}
