import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { HiMenuAlt3, HiOutlineX } from 'react-icons/hi';
import { image } from '../assets/image';
import { useNavigate } from 'react-router-dom';

export default function Header({
    screen = false
}) {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()

    const menuVariants = {
        open: {
            x: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 150,
                damping: 20,
            },
        },
        closed: {
            x: '100%',
            opacity: 0,
            transition: {
                type: 'spring',
                stiffness: 150,
                damping: 20,
            },
        },
    };

    return (
        <header className="fixed top-0 left-0 w-full px-6 py-4 transition-all backdrop-blur-md flex items-center justify-between z-50">
            {/* Logo */}
            <h1 className={`${screen ? "text-black" : "text-white"} text-2xl flex items-center font-heading font-bold`}>
                <img src={image.logo} className='h-[50px] pr-3' alt="logo" />
                Saturday
            </h1>

            {/* Hamburger Icon for Mobile */}
            <button onClick={() => setIsOpen(!isOpen)} className={`lg:hidden ${screen ? "text-black" : "text-white"} text-3xl focus:outline-none`}>
                <HiMenuAlt3 />
            </button>

            {/* Menu for larger screens */}
            <nav className="hidden lg:flex items-center space-x-6 text-white">
                {["~ Solutions", "~ Resources", "~ Partners"].map((text, index) => (
                    <a href='/' key={index} className="text-lg font-semibold font-body cursor-pointer">
                        {text}
                    </a>
                ))}
                {/* Login and Get Demo Buttons */}
                <button
                    onClick={() => {
                        navigate('/merchant/login')
                    }}
                    className={`px-4 py-2 bg-transparent border font-body ${screen ? "text-black border-black" : "text-white border-white "} font-semibold rounded-full hover:bg-white hover:text-black transition`}>
                    Login
                </button>
                <button className={`px-4 py-2  hover:scale-125 ${screen ? "text-white bg-black" : "text-black bg-white"} font-semibold font-body rounded-full hover:bg-logo hover:text-white transition`}>
                    Get Demo
                </button>
            </nav>

            {/* Full-Screen Sliding Menu for Mobile */}
            <motion.div
                initial="closed"
                animate={isOpen ? "open" : "closed"}
                variants={menuVariants}
                className={`fixed inset-0 h-screen p-4 ${screen ? "bg-white" : "bg-black"} backdrop-blur-lg flex flex-col items-center space-y-10 lg:hidden z-40`}
            >
                {/* Top Bar with Logo and Close Icon */}
                <div className="flex w-full justify-between px-2 items-center">
                    <img src={image.logo} className='h-[50px] pr-3' alt="logo" />
                    <button onClick={() => setIsOpen(!isOpen)} className={`lg:hidden ${screen ? "text-black" : "text-white"} text-3xl focus:outline-none`}>
                        <HiOutlineX />
                    </button>
                </div>

                {/* Links */}
                <div className="w-full flex-col flex space-y-3 px-2">
                    {["~ Solutions", "~ Resources", "~ Partners"].map((text, index) => (
                        <a
                            href='/'
                            key={index}
                            className={`text-2xl text-left font-body ${screen ? "text-black" : "text-white"} cursor-pointer`}
                            onClick={() => setIsOpen(false)}
                        >
                            {text}
                        </a>
                    ))}
                </div>

                {/* Login and Get Demo Buttons for Mobile */}
                <div className="w-full flex flex-row items-center space-x-5 mt-8">
                    <button
                        onClick={() => {
                            navigate('/merchant/login')
                        }}
                        className={`w-3/12 px-4 py-3 bg-transparent border border-gray-300 font-body ${screen ? "text-black" : "text-white"} font-semibold rounded-full hover:bg-white hover:text-black transition`}>
                        Login
                    </button>
                    <button
                        className={`w-3/6 px-4 py-3  hover:scale-125 ${screen ? "text-white bg-black" : "text-black bg-white"} font-semibold font-body rounded-full hover:bg-logo hover:text-white transition`}>
                        Get Demo
                    </button>
                </div>
                <p className={`font-body font-medium text-center fixed bottom-36 text-base md:text-xl ${screen ? "text-black" : "text-white"}`}>
                    Scan it, eat it, love it!
                </p>
                <h1 className={`${screen ? "text-black" : "text-white"} fixed bottom-24 text-xl text-center flex items-center font-heading font-bold`}>
                    <img src={image.logo} className='h-[40px] pr-3' alt="logo" />
                    Saturday
                </h1>

            </motion.div>

            {/* Overlay to close menu when clicking outside */}
            {isOpen && (
                <div
                    onClick={() => setIsOpen(false)}
                    className="fixed inset-0 bg-black opacity-50 z-30 lg:hidden"
                ></div>
            )}
        </header>
    );
}
