import React from 'react'
import { image } from '../../../assets/image';
import { useNavigate } from 'react-router-dom';
import Header from '../../../component/Header';

export default function Forgot() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <div className="h-[100dvh] w-full flex justify-center items-center bg-black font-body p-4">
                <div className="w-full max-w-sm md:max-w-md h-[450px] md:h-[550px] bg-white flex flex-col justify-center items-center p-6 md:p-10 space-y-6 font-body rounded shadow-lg">
                    <div className="flex justify-center items-center space-x-3">
                        <img
                            src={image.logo}
                            alt="Logo"
                            className="h-12 w-12 md:h-14 md:w-14"
                        />
                        <p className="font-heading text-3xl md:text-4xl font-semibold">
                            Saturday
                        </p>
                    </div>
                    <h1 className="text-xl md:text-3xl tracking-wider font-semibold">Password Recovery</h1>
                    <p className="text-sm md:text-base text-gray-700 text-center">
                        Receive a link to create a new password.
                    </p>
                    <div className="w-full space-y-2">
                        <label htmlFor="email" className="text-sm md:text-base">
                            Email address*
                        </label>
                        <input
                            type="text"
                            id="email"
                            className="h-12 w-full border-[1.5px] border-gray-200 bg-white px-2 outline-none rounded hover:border-2 hover:border-logo focus:border-logo transition"
                            placeholder="Email address*"
                        />
                    </div>
                    <button
                        type="button"
                        className="bg-blue-500 h-12 w-full text-white text-sm md:text-base rounded hover:bg-blue-600 transition"
                        onClick={() => {
                            navigate('/merchant/reset-link');
                        }}
                    >
                        Send Recovery Link
                    </button>
                    <a
                        href="/merchant/login"
                        className="text-text-dark text-sm md:text-base font-semibold"
                    >
                        Back to Login
                    </a>
                </div>
            </div>
        </>
    )
}
